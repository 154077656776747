<template>
  <div>

    <div class="card-toolbar mb-5">
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="inventories">{{ $t('items_report.inventory') }}</label>
              <multiselect v-model="inventory"
                           id="inventories"
                           :placeholder="$t('items_report.inventory')"
                           label="name"
                           track-by="id"
                           :options="inventories"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="item_id">{{ $t('items_report.product') }}</label>
              <div class="d-flex search-item-group">
                <select name="" id="" v-model="f_by" class="custom-select" style="width: 100px;">
                  <option value="name" selected>{{ $t('items.name') }}</option>
                  <option value="sku_code">{{ $t('items.sku_code') }}</option>
                </select>
                <multiselect v-model="product"
                             id="item_id"
                             :placeholder="$t('items_report.product')"
                             :label="f_by?f_by:'sku_code'"
                             track-by="id"
                             :options="items"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :internal-search="false"
                             @search-change="getItems(f_by, $event)">
                </multiselect>
              </div>
            </div>
            <div class="form-group col-md-6 mb-5">
              <label for="from_date">{{ $t('items_report.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mb-5">
              <label for="to_date">{{ $t('items_report.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">
        <div class="row mb-10">
          <div class="col-md-12 mt-10" id="sectionForExportTitle">
            <h4 class="text-center">{{ $t('items_report.items_report') }}</h4>
            <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
          </div>

        </div>
        <div class="col-12 mb-5 p-0 d-flex justify-content-end">
          <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
            <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
              <span><i class="fa fa-file-excel"></i> {{ $t('excel') }}</span>
            </button>
            <button class="dropdown-item" @click="printPDFData('summaryTableDetails', 'pdf')">
              <span><i class="fa fa-print"></i> <span>{{ $t('export_pdf') }}</span></span>
            </button>
            <button class="dropdown-item" @click="printData('summaryTableDetails')">
              <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
            </button>
            <button class="dropdown-item" @click="getDataForExport('sectionForExport', 'excel')">
              <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
            </button>
          </b-dropdown>
        </div>
        <table class="table table-bordered" id="summaryTableDetails">
          <thead>
          <tr>
            <th>{{ $t('items_report.inventory') }}</th>
            <th>{{ $t('items_report.product') }}</th>
            <th>{{ $t('items_report.quantity') }}</th>
            <th>{{ $t('items_report.trans_affect_name') }}</th>
            <th>{{ $t('items_report.transaction_id') }}</th>
            <th>{{ $t('daily_product_sales.unit_price') }}</th>
            <th>{{ $t('currency') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in data_report_details">
            <tr :key="'a'+index">
              <td :colspan="7">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.data" :key="'a'+getRandom()+index2">
              <td>{{ row2.inventory_name }}</td>
              <td>{{ row2.item_name }}</td>
              <td>{{ row2.quantity }}</td>
              <td>{{ row2.trans_affect_name }}</td>
              <td>{{ row2.transaction_id }}</td>
              <td>{{ row2.price_per_unit }}</td>
              <td>{{ row2.currency_name }}</td>
            </tr>
          </template>

          </tbody>
        </table>


        <div class="col-12 text-center" v-if="page != last_page">
          <button class="btn btn-warning" @click="loadMore">
            <p class="mb-0">
              <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
              {{ $t('load_more') }}
            </p>
          </button>
        </div>


        <!--                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">-->

        <!--                </v-server-table>-->

      </div>
    </div>
    <!--end::customer-->
    <div class="export-container">
      <div class="section-for-export" id="sectionForExport">
        <table class="table" style="direction: rtl;">
          <thead>
          <tr>
            <th>{{ $t('items_report.inventory') }}</th>
            <th>{{ $t('items_report.product') }}</th>
            <th>{{ $t('items_report.quantity') }}</th>
            <th>{{ $t('items_report.trans_affect_name') }}</th>
            <th>{{ $t('items_report.transaction_id') }}</th>
            <th>{{ $t('daily_product_sales.unit_price') }}</th>
            <th>{{ $t('currency') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in data_report_details">
            <tr :key="'a'+index">
              <td :colspan="7">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.data" :key="'a'+getRandom()+index2">
              <td>{{ row2.inventory_name }}</td>
              <td>{{ row2.item_name }}</td>
              <td>{{ row2.quantity }}</td>
              <td>{{ row2.trans_affect_name }}</td>
              <td>{{ row2.transaction_id }}</td>
              <td>{{ row2.price_per_unit }}</td>
              <td>{{ row2.currency_name }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'ItemsReport'"></report-main-header>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

export default {
  name: "index-items-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/items/movements',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: true,

      filter: {
        sortBy: 'id',
      },
      filters: {
        inventory_id: null,
        item_id: null,
        from_date: null,
        to_date: null,
        group_by_item: 1
      },
      // status_list: [],
      columns: ['inventory_name', 'item_name', 'quantity', 'trans_affect_name', 'transaction_id'],
      header: ['inventory_name', 'item_name', 'quantity', 'trans_affect_name', 'transaction_id'],
      data: [],
      dataList: [],
      data_report_details: [],

      inventories: [],
      items: [],
      inventory: null,
      product: null,
      f_by: 'name',
      page: 1,
      last_page: 1,
    }
  },
  watch: {
    inventory: function (val) {
      if (val) {
        this.filters.inventory_id = val.id;
      } else {
        this.filters.inventory_id = null;
      }
    },
    product: function (val) {
      if (val) {
        this.filters.item_id = val.id;
      } else {
        this.filters.item_id = null;
      }
    },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          inventory_name: that.$t('items_report.inventory'),
          item_name: that.$t('items_report.product'),
          quantity: that.$t('items_report.quantity'),
          trans_affect_name: that.$t('items_report.trans_affect_name'),
          transaction_id: that.$t('items_report.transaction_id'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.items_report")}]);
    this.getInventories();
  },
  methods: {
    ...cssStypeForPrintReport,

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.getData(1)
    },
    resetFilter() {
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.inventory_id = null;
      this.filters.item_id = null;
      this.inventory = null;
      this.product = null;

      this.getData(1)
    },

    getInventories() {
      ApiService.get(this.mainRouteDependency + `/inventories`).then((response) => {
        this.inventories = response.data.data;
      });
    },

    getItems(f_by = null, filter = null) {
      if (filter && filter.length > 3 && f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    /**
     * actions export
     * @param tableId
     * @param _export
     */
    getDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute,{params: {...this.filters, export: 1}}).then((response) => {
          this.dataList = response.data.data;
      // if (_export == 'PDF')
      //   this.printData(tableId);
      // if (_export == 'excel')
        this.convertTableToExcelReport(tableId);
      });
    },

    printData(tableId) {
      this.setReportLog('pdf', 'items report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'items report');

      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('MENU.items_report'), this.$t('MENU.items_report'));

    },
    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'items report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.items_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    },
    getData(page = 1) {
      ApiService.get(this.mainRoute, {params: {...this.filters, page: page}}).then((response) => {
        if (page == 1) {
          this.data_report_details = response.data.data.data;
        } else {
          this.data_report_details.push(...response.data.data.data);
        }
        this.last_page = response.data.data.last_page;
      });
    },
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
.export-container {
  overflow: scroll;
  width: 1px;
  height: 1px;
}
</style>